var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { createElement as _createElement } from "react";
import { XList } from "@bluelibs/x-ui-admin";
import { Routes } from "@bundles/UIAppBundle";
import { Service } from "@bluelibs/core";
let WorldList = class WorldList extends XList {
    build() {
        const { UIComponents, router } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "name.values",
                title: t("management.worlds.fields.name.values"),
                key: "management.worlds.fields.name.values",
                dataIndex: ["name", "values"],
                sorter: true,
                render: (value, model) => {
                    return (_jsx(_Fragment, { children: value &&
                            value.map((value, idx) => {
                                const props = {
                                    type: "object",
                                    value,
                                };
                                return (_createElement(UIComponents.AdminListItemRenderer, { ...props, key: idx }));
                            }) }));
                },
            },
            {
                id: "description.values",
                title: t("management.worlds.fields.description.values"),
                key: "management.worlds.fields.description.values",
                dataIndex: ["description", "values"],
                sorter: true,
                render: (value, model) => {
                    return (_jsx(_Fragment, { children: value &&
                            value.map((value, idx) => {
                                const props = {
                                    type: "object",
                                    value,
                                };
                                return (_createElement(UIComponents.AdminListItemRenderer, { ...props, key: idx }));
                            }) }));
                },
            },
            {
                id: "slug",
                title: t("management.worlds.fields.slug"),
                key: "management.worlds.fields.slug",
                dataIndex: ["slug"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "startCoordinates.position",
                title: t("management.worlds.fields.startCoordinates.position"),
                key: "management.worlds.fields.startCoordinates.position",
                dataIndex: ["startCoordinates", "position"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "startCoordinates.rotation",
                title: t("management.worlds.fields.startCoordinates.rotation"),
                key: "management.worlds.fields.startCoordinates.rotation",
                dataIndex: ["startCoordinates", "rotation"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "startCoordinates.scale",
                title: t("management.worlds.fields.startCoordinates.scale"),
                key: "management.worlds.fields.startCoordinates.scale",
                dataIndex: ["startCoordinates", "scale"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "monthlyPrice",
                title: t("management.worlds.fields.monthlyPrice"),
                key: "management.worlds.fields.monthlyPrice",
                dataIndex: ["monthlyPrice"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "yearlyPrice",
                title: t("management.worlds.fields.yearlyPrice"),
                key: "management.worlds.fields.yearlyPrice",
                dataIndex: ["yearlyPrice"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "yearlyProductId",
                title: t("management.worlds.fields.yearlyProductId"),
                key: "management.worlds.fields.yearlyProductId",
                dataIndex: ["yearlyProductId"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "monthlyProductId",
                title: t("management.worlds.fields.monthlyProductId"),
                key: "management.worlds.fields.monthlyProductId",
                dataIndex: ["monthlyProductId"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "merchantDefaultVideoCoordinates.position",
                title: t("management.worlds.fields.merchantDefaultVideoCoordinates.position"),
                key: "management.worlds.fields.merchantDefaultVideoCoordinates.position",
                dataIndex: ["merchantDefaultVideoCoordinates", "position"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "merchantDefaultVideoCoordinates.rotation",
                title: t("management.worlds.fields.merchantDefaultVideoCoordinates.rotation"),
                key: "management.worlds.fields.merchantDefaultVideoCoordinates.rotation",
                dataIndex: ["merchantDefaultVideoCoordinates", "rotation"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "merchantDefaultVideoCoordinates.scale",
                title: t("management.worlds.fields.merchantDefaultVideoCoordinates.scale"),
                key: "management.worlds.fields.merchantDefaultVideoCoordinates.scale",
                dataIndex: ["merchantDefaultVideoCoordinates", "scale"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "capacity",
                title: t("management.worlds.fields.capacity"),
                key: "management.worlds.fields.capacity",
                dataIndex: ["capacity"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "usageRecommendations",
                title: t("management.worlds.fields.usageRecommendations"),
                key: "management.worlds.fields.usageRecommendations",
                dataIndex: ["usageRecommendations"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "assetsCount",
                title: t("management.worlds.fields.assetsCount"),
                key: "management.worlds.fields.assetsCount",
                dataIndex: ["assetsCount"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "projectsCount",
                title: t("management.worlds.fields.projectsCount"),
                key: "management.worlds.fields.projectsCount",
                dataIndex: ["projectsCount"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "isFeatured",
                title: t("management.worlds.fields.isFeatured"),
                key: "management.worlds.fields.isFeatured",
                dataIndex: ["isFeatured"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "boolean",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "isHidden",
                title: t("management.worlds.fields.isHidden"),
                key: "management.worlds.fields.isHidden",
                dataIndex: ["isHidden"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "boolean",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "createdAt",
                title: t("management.worlds.fields.createdAt"),
                key: "management.worlds.fields.createdAt",
                dataIndex: ["createdAt"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "updatedAt",
                title: t("management.worlds.fields.updatedAt"),
                key: "management.worlds.fields.updatedAt",
                dataIndex: ["updatedAt"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "worldFile",
                title: t("management.worlds.fields.worldFile"),
                key: "management.worlds.fields.worldFile",
                dataIndex: ["worldFile"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "file",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "meshFile",
                title: t("management.worlds.fields.meshFile"),
                key: "management.worlds.fields.meshFile",
                dataIndex: ["meshFile"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "file",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "pictureGallery",
                title: t("management.worlds.fields.pictureGallery"),
                key: "management.worlds.fields.pictureGallery",
                dataIndex: ["pictureGallery"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "fileGroup",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "createdBy",
                title: t("management.worlds.fields.createdBy"),
                key: "management.worlds.fields.createdBy",
                dataIndex: ["createdBy"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.USERS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "fullName",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "updatedBy",
                title: t("management.worlds.fields.updatedBy"),
                key: "management.worlds.fields.updatedBy",
                dataIndex: ["updatedBy"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.USERS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "fullName",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "assetPositions",
                title: t("management.worlds.fields.assetPositions"),
                key: "management.worlds.fields.assetPositions",
                dataIndex: ["assetPositions"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.WORLDS_ASSET_POSITIONS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "_id",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "category",
                title: t("management.worlds.fields.category"),
                key: "management.worlds.fields.category",
                dataIndex: ["category"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.WORLD_CATEGORIES_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "name.text",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "reviews",
                title: t("management.worlds.fields.reviews"),
                key: "management.worlds.fields.reviews",
                dataIndex: ["reviews"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.WORLD_REVIEWS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "_id",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "restrictedToMerchant",
                title: t("management.worlds.fields.restrictedToMerchant"),
                key: "management.worlds.fields.restrictedToMerchant",
                dataIndex: ["restrictedToMerchant"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.MERCHANTS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "fullName",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
        ]);
    }
    static getSortMap() {
        return {
            worldFile: "worldFile._id",
            meshFile: "meshFile._id",
            pictureGallery: "pictureGallery._id",
            createdBy: "createdBy.fullName",
            updatedBy: "updatedBy.fullName",
            assetPositions: "assetPositions._id",
            category: "category.name.text",
            reviews: "reviews._id",
            restrictedToMerchant: "restrictedToMerchant.fullName",
        };
    }
    static getRequestBody() {
        return {
            _id: 1,
            name: {
                values: {
                    lang: 1,
                    text: 1,
                },
                text: 1,
            },
            slug: 1,
            description: {
                values: {
                    lang: 1,
                    text: 1,
                },
                text: 1,
            },
            startCoordinates: {
                position: 1,
                rotation: 1,
                scale: 1,
            },
            monthlyPrice: 1,
            yearlyPrice: 1,
            yearlyProductId: 1,
            monthlyProductId: 1,
            merchantDefaultVideoCoordinates: {
                position: 1,
                rotation: 1,
                scale: 1,
            },
            capacity: 1,
            usageRecommendations: 1,
            assetsCount: 1,
            projectsCount: 1,
            isFeatured: 1,
            isHidden: 1,
            createdAt: 1,
            updatedAt: 1,
            worldFile: {
                _id: 1,
                downloadUrl: 1,
                name: 1,
            },
            worldFileId: 1,
            meshFile: {
                _id: 1,
                downloadUrl: 1,
                name: 1,
            },
            meshFileId: 1,
            pictureGallery: {
                _id: 1,
                name: 1,
                files: {
                    downloadUrl: 1,
                    name: 1,
                },
            },
            pictureGalleryId: 1,
            createdBy: {
                _id: 1,
                fullName: 1,
            },
            createdById: 1,
            updatedBy: {
                _id: 1,
                fullName: 1,
            },
            updatedById: 1,
            assetPositions: {
                _id: 1,
            },
            category: {
                _id: 1,
                name: {
                    text: 1,
                    values: {
                        lang: 1,
                        text: 1,
                    },
                },
            },
            categoryId: 1,
            reviews: {
                _id: 1,
            },
            restrictedToMerchant: {
                _id: 1,
                fullName: 1,
            },
            restrictedToMerchantId: 1,
        };
    }
};
WorldList = __decorate([
    Service({ transient: true })
], WorldList);
export { WorldList };
