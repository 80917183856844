var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { createElement as _createElement } from "react";
import { XList } from "@bluelibs/x-ui-admin";
import { Routes } from "@bundles/UIAppBundle";
import { Service } from "@bluelibs/core";
let ProductList = class ProductList extends XList {
    build() {
        const { UIComponents, router } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "name.values",
                title: t("management.products.fields.name.values"),
                key: "management.products.fields.name.values",
                dataIndex: ["name", "values"],
                sorter: true,
                render: (value, model) => {
                    return (_jsx(_Fragment, { children: value &&
                            value.map((value, idx) => {
                                const props = {
                                    type: "object",
                                    value,
                                };
                                return (_createElement(UIComponents.AdminListItemRenderer, { ...props, key: idx }));
                            }) }));
                },
            },
            {
                id: "description.values",
                title: t("management.products.fields.description.values"),
                key: "management.products.fields.description.values",
                dataIndex: ["description", "values"],
                sorter: true,
                render: (value, model) => {
                    return (_jsx(_Fragment, { children: value &&
                            value.map((value, idx) => {
                                const props = {
                                    type: "object",
                                    value,
                                };
                                return (_createElement(UIComponents.AdminListItemRenderer, { ...props, key: idx }));
                            }) }));
                },
            },
            {
                id: "mediaTypes",
                title: t("management.products.fields.mediaTypes"),
                key: "management.products.fields.mediaTypes",
                dataIndex: ["mediaTypes"],
                sorter: true,
                render: (value, model) => {
                    return (_jsx(_Fragment, { children: value &&
                            value.map((value, idx) => {
                                const props = {
                                    type: "object",
                                    value,
                                };
                                return (_createElement(UIComponents.AdminListItemRenderer, { ...props, key: idx }));
                            }) }));
                },
            },
            {
                id: "price",
                title: t("management.products.fields.price"),
                key: "management.products.fields.price",
                dataIndex: ["price"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "vat",
                title: t("management.products.fields.vat"),
                key: "management.products.fields.vat",
                dataIndex: ["vat"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "manufacturer",
                title: t("management.products.fields.manufacturer"),
                key: "management.products.fields.manufacturer",
                dataIndex: ["manufacturer"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "href",
                title: t("management.products.fields.href"),
                key: "management.products.fields.href",
                dataIndex: ["href"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "tags",
                title: t("management.products.fields.tags"),
                key: "management.products.fields.tags",
                dataIndex: ["tags"],
                sorter: true,
                render: (value, model) => {
                    return (_jsx(_Fragment, { children: value &&
                            value.map((value, idx) => {
                                const props = {
                                    type: "string",
                                    value,
                                };
                                return (_createElement(UIComponents.AdminListItemRenderer, { ...props, key: idx }));
                            }) }));
                },
            },
            {
                id: "isPhysical",
                title: t("management.products.fields.isPhysical"),
                key: "management.products.fields.isPhysical",
                dataIndex: ["isPhysical"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "boolean",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "inventory.status",
                title: t("management.products.fields.inventory.status"),
                key: "management.products.fields.inventory.status",
                dataIndex: ["inventory", "status"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "enum",
                        value,
                        labelify: true,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "inventory.stockKeepingUnit",
                title: t("management.products.fields.inventory.stockKeepingUnit"),
                key: "management.products.fields.inventory.stockKeepingUnit",
                dataIndex: ["inventory", "stockKeepingUnit"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "inventory.barcode",
                title: t("management.products.fields.inventory.barcode"),
                key: "management.products.fields.inventory.barcode",
                dataIndex: ["inventory", "barcode"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "options.sizes",
                title: t("management.products.fields.options.sizes"),
                key: "management.products.fields.options.sizes",
                dataIndex: ["options", "sizes"],
                sorter: true,
                render: (value, model) => {
                    return (_jsx(_Fragment, { children: value &&
                            value.map((value, idx) => {
                                const props = {
                                    type: "string",
                                    value,
                                };
                                return (_createElement(UIComponents.AdminListItemRenderer, { ...props, key: idx }));
                            }) }));
                },
            },
            {
                id: "options.colours",
                title: t("management.products.fields.options.colours"),
                key: "management.products.fields.options.colours",
                dataIndex: ["options", "colours"],
                sorter: true,
                render: (value, model) => {
                    return (_jsx(_Fragment, { children: value &&
                            value.map((value, idx) => {
                                const props = {
                                    type: "string",
                                    value,
                                };
                                return (_createElement(UIComponents.AdminListItemRenderer, { ...props, key: idx }));
                            }) }));
                },
            },
            {
                id: "options.materials",
                title: t("management.products.fields.options.materials"),
                key: "management.products.fields.options.materials",
                dataIndex: ["options", "materials"],
                sorter: true,
                render: (value, model) => {
                    return (_jsx(_Fragment, { children: value &&
                            value.map((value, idx) => {
                                const props = {
                                    type: "string",
                                    value,
                                };
                                return (_createElement(UIComponents.AdminListItemRenderer, { ...props, key: idx }));
                            }) }));
                },
            },
            {
                id: "shipping.weight",
                title: t("management.products.fields.shipping.weight"),
                key: "management.products.fields.shipping.weight",
                dataIndex: ["shipping", "weight"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "shipping.originCountryOrRegion",
                title: t("management.products.fields.shipping.originCountryOrRegion"),
                key: "management.products.fields.shipping.originCountryOrRegion",
                dataIndex: ["shipping", "originCountryOrRegion"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "shipping.harmonisedSystemCode",
                title: t("management.products.fields.shipping.harmonisedSystemCode"),
                key: "management.products.fields.shipping.harmonisedSystemCode",
                dataIndex: ["shipping", "harmonisedSystemCode"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "seo.title",
                title: t("management.products.fields.seo.title"),
                key: "management.products.fields.seo.title",
                dataIndex: ["seo", "title"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "seo.description",
                title: t("management.products.fields.seo.description"),
                key: "management.products.fields.seo.description",
                dataIndex: ["seo", "description"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "createdById",
                title: t("management.products.fields.createdById"),
                key: "management.products.fields.createdById",
                dataIndex: ["createdById"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "objectId",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "updatedById",
                title: t("management.products.fields.updatedById"),
                key: "management.products.fields.updatedById",
                dataIndex: ["updatedById"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "objectId",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "createdAt",
                title: t("management.products.fields.createdAt"),
                key: "management.products.fields.createdAt",
                dataIndex: ["createdAt"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "updatedAt",
                title: t("management.products.fields.updatedAt"),
                key: "management.products.fields.updatedAt",
                dataIndex: ["updatedAt"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "media",
                title: t("management.products.fields.media"),
                key: "management.products.fields.media",
                dataIndex: ["media"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.MEDIAS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "name",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "category",
                title: t("management.products.fields.category"),
                key: "management.products.fields.category",
                dataIndex: ["category"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.PRODUCT_CATEGORIES_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "name",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "merchant",
                title: t("management.products.fields.merchant"),
                key: "management.products.fields.merchant",
                dataIndex: ["merchant"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.MERCHANTS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "fullName",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "createdBy",
                title: t("management.products.fields.createdBy"),
                key: "management.products.fields.createdBy",
                dataIndex: ["createdBy"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.USERS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "fullName",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "updatedBy",
                title: t("management.products.fields.updatedBy"),
                key: "management.products.fields.updatedBy",
                dataIndex: ["updatedBy"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.USERS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "fullName",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
        ]);
    }
    static getSortMap() {
        return {
            media: "media.name",
            category: "category.name",
            merchant: "merchant.fullName",
            createdBy: "createdBy.fullName",
            updatedBy: "updatedBy.fullName",
        };
    }
    static getRequestBody() {
        return {
            _id: 1,
            name: {
                values: {
                    lang: 1,
                    text: 1,
                },
                text: 1,
            },
            description: {
                values: {
                    lang: 1,
                    text: 1,
                },
                text: 1,
            },
            mediaTypes: {
                type: 1,
                typeFor3D: 1,
            },
            price: 1,
            vat: 1,
            manufacturer: 1,
            href: 1,
            tags: 1,
            inventory: {
                status: 1,
            },
            options: {
                sizes: 1,
                colours: 1,
                materials: 1,
            },
            shipping: {
                weight: 1,
                originCountryOrRegion: 1,
                harmonisedSystemCode: 1,
            },
            seo: {
                title: 1,
                description: 1,
            },
            createdById: 1,
            updatedById: 1,
            createdAt: 1,
            updatedAt: 1,
            media: {
                _id: 1,
                name: 1,
            },
            mediaId: 1,
            category: {
                _id: 1,
                name: 1,
            },
            categoryId: 1,
            merchant: {
                _id: 1,
                fullName: 1,
            },
            merchantId: 1,
            createdBy: {
                _id: 1,
                fullName: 1,
            },
            updatedBy: {
                _id: 1,
                fullName: 1,
            },
        };
    }
};
ProductList = __decorate([
    Service({ transient: true })
], ProductList);
export { ProductList };
